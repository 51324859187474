<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#283e51"
      spinner="spinner"
    />
    <v-layout class="mainfont" wrap justify-center pt-8>
      <v-flex xs11 lg8 pl-3 pt-4>
        <!-- <v-layout wrap justify-center pt-5 style="background-color: white;">
                      <v-flex style="background-color: white;" xs4 sm1 xs12 class="text-sm-left text-xs-center">
                          <v-btn class="mx-2" small fab dark @click="$refs.calendar.prev()">
                              <v-icon center>mdi-arrow-left</v-icon>
  
                          </v-btn>
                      </v-flex>
                      <v-flex xs4 sm2 pt-2 text-center style="font-size: 17px;font-weight: bold;">
                          {{ formattedSelectedMonth }}
                      </v-flex>
  
                      <v-flex sm1 xs4 class="text-sm-right text-xs-center">
                          <v-btn class="mx-2" small fab dark @click="$refs.calendar.next()">
  
                              <v-icon center>mdi-arrow-right</v-icon>
                          </v-btn>
                      </v-flex>
  
                      <v-flex xs12 class="mt-6">
                          <v-sheet height="700">
                              <v-calendar
    class="selectedDates"
    ref="calendar"
    range
    v-model="selectedDates2"
    :type="type"
    :end="end"
    color="primary"
    multiple
  />
  
                          </v-sheet>
                      </v-flex>
                  </v-layout> -->

        <!-- <v-date-picker
            color="rgba(255, 98, 0, 1)"
            no-title
            ref="datepicker"
            :picker-date.sync="pickerDate"
            class="custofont LexendFont1"
            theme="custom-theme"
            :class="{ 'custom-date-picker': true }"
            v-model="selectedDate"
            range
            :allowed-dates="filterAllowedDates"
            :min="minDate"
            full-width
            style="height: 500px; line-height: 60px; font-size: 28px"
          >
            <v-spacer></v-spacer>
          </v-date-picker> -->

        <v-date-picker
          color="#ff6200"
          no-title
          ref="datepicker"
          :picker-date.sync="pickerDate"
          class="custofont LexendFont1"
          theme="custom-theme"
          :class="{ 'custom-date-picker': true }"
          v-model="selectedDate"
          range
          :allowed-dates="filterAllowedDates"
          :min="nowDatee"
          :max="calculateMaxDate()"
          full-width
          style="height: 500px; line-height: 60px; font-size: 28px"
        >
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-flex>

      <v-flex pa-4 align-self-center xs11 lg4>
        <v-layout pl-3 pr-3 wrap justify-center style="background-color: white">
          <v-flex
            pt-3
            xs12
            text-left
            style="font-size: 25px; font-weight: bold"
          >
            <span>IB Booking User</span>
          </v-flex>
          <v-flex
            pt-3
            xs12
            text-left
            style="font-size: 19px; font-weight: bold"
          >
            <span>{{ $route.query.accName }}</span
            >&nbsp;({{ range }})
          </v-flex>

          <v-flex xs12 text-left pt-1>
            <span style="color: grey">Selected Date </span>
          </v-flex>
          <v-flex xs12 pt-1>
            <span
              style="
                font-weight: 500;
                font-size: 18px;

                font-family: 'Inconsolata', monospace;
                text-transform: none;
              "
              ><span>{{ displayDate(finalarr[0]) }}</span>
              <span v-if="finalarr.length > 1">
                - {{ displayDate(finalarr[finalarr.length - 1]) }}
              </span>
            </span>
          </v-flex>

          <v-flex xs12 text-left pt-1 pb-1>
            <span style="color: grey">Availability </span>
          </v-flex>
          <v-flex text-center pa-2 xs12 style="background-color: #283e51">
            <span style="color: white"
              >{{ availableRooms }} Rooms Available</span
            >
          </v-flex>
          <v-flex xs12 pt-1>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 v-if="availableRooms != 'Sorry no'">
            <v-layout wrap justify-center>
              <v-flex xs12 pt-3 pt-1>
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="Guest Name"
                  type="text"
                  v-model="guestName"
                  :rules="guestNameRules"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-0 pb-1>
                <v-radio-group v-model="radio" row hide-details="auto">
                  <v-radio label="Official" value="Official"></v-radio>
                  <v-radio label="Public" value="Public"></v-radio>
                </v-radio-group>
              </v-flex>

              <v-flex xs12 v-if="radio === 'Official'">
                <v-flex xs12 pt-3>
                  <v-select
                    :items="priorityArray"
                    dense
                    :rules="priorityRules"
                    label="Category"
                    v-model="priority"
                    outlined
                    hide-details="auto"
                  ></v-select>
                </v-flex>

                <v-flex
                  xs12
                  pt-3
                  pt-1
                  v-if="
                    priority === 'MINISTER' ||
                    priority === 'SPEAKER' ||
                    priority === 'DEPUTY SPEAKER' ||
                    priority === 'GOVERNMENT CHIEF WHIP' ||
                    priority === 'MP' ||
                    priority === 'MLA' ||
                    priority === 'HIGH COURT OF KERALA JUDGE' ||
                    priority === 'ADVOCATE GENERAL' ||
                    priority === 'CHIEF SECRETARY' ||
                    priority ===
                      'ADDITIONAL CHIEF SECRETARY/PRINCIPAL SECRETARY/SECRETARY (FORESTS AND WILDLIFE DEPARTMENT)' ||
                    priority === 'FOREST OFFICERS ON INSPECTION' ||
                    priority === 'MINISTER' ||
                    priority === 'SPEAKER' ||
                    priority === 'DEPUTY SPEAKER' ||
                    priority === 'GOVERNMENT CHIEF WHIP' ||
                    priority === 'MP' ||
                    priority === 'MLA' ||
                    priority === 'HIGH COURT OF KERALA JUDGE' ||
                    priority === 'ADVOCATE GENERAL' ||
                    priority === 'CHIEF SECRETARY' ||
                    priority ===
                      'ADDITIONAL CHIEF SECRETARY/PRINCIPAL SECRETARY/SECRETARY (FORESTS AND WILDLIFE DEPARTMENT)' ||
                    priority === 'FOREST OFFICER' ||
                    priority === 'RETIRED FOREST OFFICER'
                  "
                >
                  <v-select
                    :items="prioritystatusArray"
                    dense
                    :rules="statusRules"
                    label="Status"
                    v-model="prioritystatus"
                    outlined
                    hide-details="auto"
                  ></v-select>
                </v-flex>

                <v-flex xs12 pt-2>
                  <span>Upload Official Id Card</span>
                  <v-layout wrap>
                    <v-flex xs6 sm12 md12 v-if="priorityFile">
                      <v-chip class="ma-2" outlined>
                        <span>{{ truncateFileName(priorityFile.name) }}</span>
                      </v-chip>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap>
                    <v-flex xs12 pt-2 pb-1>
                      <v-btn @click="$refs.priorityfileInput.click()"
                        ><span
                          >Upload <v-icon>mdi-cloud-upload</v-icon>
                        </span></v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <input
                    v-show="false"
                    ref="priorityfileInput"
                    type="file"
                    @change="handleFileChangePriority"
                    accept=".pdf, .jpeg, .png"
                  />
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-3 pt-1>
                <v-text-field
                  class="inputPrice"
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="No. of rooms Required"
                  type="number"
                  :rules="roomsRequiredRules"
                  hide-spin-buttons
                  v-model="reqRooms"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 pt-3 pt-1>
                <v-text-field
                  class="inputPrice"
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="No. of children (Age below 12 years)"
                  type="number"
                  :rules="personsAccompanyingRules"
                  v-model="children"
                  hide-details
                >
                </v-text-field>
                <!-- <span style="font-size: 12px; color: rgb(120, 120, 120);"
                    >Age below 12 years</span
                  > -->

                <v-flex xs12 pt-3 pt-0>
                  <v-text-field
                    class="inputPrice"
                    color="black"
                    outlined
                    background-color="white"
                    dense
                    label="No: of Adults"
                    type="number"
                    :rules="personsAccompanyingRules"
                    v-model="adult"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-flex>
              </v-flex>

              <v-flex xs12 pt-3 pt-1>
                <v-text-field
                  class="inputPrice"
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="Guest Phone No."
                  type="number"
                  :rules="guestPhoneRules"
                  v-model="guestMobileNo"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-3 pt-1>
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="E-mail Address "
                  :rules="emailRules"
                  type="text"
                  v-model="guestEmailId"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-3 pt-1>
                <v-textarea
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="Guest Address "
                  :rules="addressRules"
                  type="text"
                  v-model="guestAddress"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>

              <!-- <v-flex xs12 pt-3 pt-1>
                  <v-autocomplete
                    outlined
                    dense
                    label="Food Preference"
                    color="black"
                    :items="itemArray2"
                    v-model="mealsType"
                    item-text="name"
                    item-value="name"
                    item-color="#FF1313"
                    hide-details="auto"
                    clearable
                    class="custom-autocomplete  mainfont"
                  >
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex> -->

              <v-flex xs12 pt-2>
                <v-autocomplete
                  outlined
                  label="Id Type"
                  dense
                  color="black"
                  :items="idcardtypes"
                  v-model="idtype"
                  item-text="name"
                  item-value="name"
                  :rules="idTypeRules"
                  item-color="#FF1313"
                  hide-details="auto"
                  clearable
                  class="custom-autocomplete mainfont"
                >
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 pt-2 v-if="idtype === 'Election Id Card'">
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="Election ID"
                  type="Id Number"
                  :rules="electionIdRules"
                  v-model="idNo"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-2 v-if="idtype === 'Driving License'">
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="License Number"
                  type="Id Number"
                  :rules="drivingLicenseRules"
                  v-model="idNo"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-2 v-if="idtype === 'Aadhaar Card'">
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="Aadhar Number"
                  type="Id Number"
                  :rules="aadharCradRules"
                  v-model="idNo"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-2>
                <span>Upload Id Card</span>
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm12
                    md12
                    v-for="(item, i) in selectedFile"
                    :key="i"
                  >
                    <v-chip
                      @click:close="removeFiles(i)"
                      class="ma-2"
                      close
                      outlined
                    >
                      <span>{{ truncateFileName(item.name) }}</span>
                    </v-chip>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 pt-2 pb-1>
                    <v-btn @click="$refs.fileInput.click()" width="30%"
                      ><span
                        >Upload <v-icon>mdi-cloud-upload</v-icon>
                      </span></v-btn
                    >
                  </v-flex>
                </v-layout>
                <input
                  v-show="false"
                  type="file"
                  ref="fileInput"
                  multiple
                  @change="handleFileChange"
                  accept=".pdf, .jpeg, .png"
                />
                <span style="font-size: 12px; color: rgb(120, 120, 120)"
                  >Note: Upload each Individual's ID except children.</span
                >
              </v-flex>

              <v-flex xs12 pt-0>
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  label="Any Other Details"
                  type="text"
                  v-model="anyOtherDetails"
                  :rules="anyOtherDetailsRules"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <!-- <v-flex xs12 pt-3 pt-1 pb-2>
                  <v-select
                    :items="userTypeArray"
                    dense
                    :rules="usertypeRules"
                    label="User Type"
                    v-model="usertype"
                    outlined
                    hide-details="auto"
                  ></v-select>
                </v-flex> -->

              <v-flex xs1 pt-0>
                <v-checkbox
                  class="inputPrice pb-2"
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  hide-details="auto"
                  v-model="isCheck"
                ></v-checkbox>
              </v-flex>

              <v-flex xs11 pt-2>
                <span style="font-size: 13px">
                  I &nbsp; accept &nbsp; the &nbsp;

                  <span
                    id="readMore"
                    style="color: rgb(2, 2, 87); font-size: 13px"
                    @click="terms()"
                    @mouseover="changeCursor"
                    @mouseout="restoreCursor"
                    class="pt-2"
                    ><u>Terms and Conditions</u></span
                  >
                </span>
              </v-flex>

              <v-flex xs12 pt-3 pt-1>
                <v-text-field
                  color="black"
                  outlined
                  background-color="white"
                  dense
                  label="Total Amount"
                  type="number"
                  disabled
                  v-model="totalAmount"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>

              <v-flex xs12 pt-3 pb-4>
                <v-btn @click="validation()" block color="#283e51">
                  <span style="color: white">SUBMIT</span>
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-else xs12>
            <v-layout wrap style="height: 500px"> </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="memberdialog"
        max-width="350px"
      >
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs10 pt-4 text-center>
              <span
                class="mainfont"
                style="color: black; font-size: 18px; font-weight: lighter"
              >
                Only {{ availableRooms }} rooms available
              </span>
            </v-flex>
            <v-flex text-center>
              <v-btn color="green" class="mainfont" text @click="submit2()"
                >OK</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-dialog v-model="tandc" width="600">
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs10 py-6>
            <v-layout wrap justify-center>
              <v-flex xs12 pb-2>
                <span style="font-size: 30px"><u>Terms & Conditions</u></span>
              </v-flex>
            </v-layout>
            <v-flex lg12>
              <span
                >These Terms and Conditions align with APPENDIX II, specifically
                “Paragraph 12.2.3 in Volume 1 [Rules regulating the Occupation
                of Forest Bungalows and Forest Rest Houses] of the Kerala Forest
                Code Volume III” and the “Guidelines issued for the purpose by
                the Principal Chief Conservator of Forests & Head of Forest
                Force, Kerala” <br />
                <br />
                <ul>
                  <li>
                    Forest Inspection Bungalows and Rest Houses are primarily
                    intended for the use of Forest officers travelling on duty.
                    <b>
                      Forest Officers shall have, at all times preferential
                      claims for their occupation.
                    </b>
                    While considering application for reservation from the
                    Forest officers, reservation shall be confirmed in the order
                    of seniority of the applications.
                  </li>

                  <li>
                    Reservations will be strictly based on the priorities set
                    out in Clause 3 of the Guidelines for reservation of Forest
                    Inspection Bungalows/ Rest Houses issued by the Principal
                    Chief Conservator of Forests & Head of Forest Force, Kerala.
                    Clause 3 reads as follows
                    <br /><br />
                    <i
                      >While making reservation, the following priority will be
                      followed</i
                    >
                    <br />
                    <br />
                    <ul>
                      <li>
                        <i
                          >Reservation for official use by Hon'ble Ministers,
                          Speaker, Deputy Speaker, Government Chief Whip, MPs,
                          MLAs, Judges of the High Court of Kerala, Advocate
                          General, Chief Secretary, Additional Chief Secretary/
                          Principal Secretary/ Secretary (Forests & Wildlife
                          Department) and the Forest Officers on inspection.
                        </i>
                      </li>
                      <br />
                      <li>
                        <i
                          >Reservation for private use by Hon'ble Ministers,
                          Speaker, Deputy Speaker, Government Chief Whip, MPs,
                          MLAs, Judges of the High Court of Kerala, Advocate
                          General, Chief Secretary, Additional Chief Secretary/
                          Principal Secretary/ Secretary (Forests & Wildlife
                          Department), and forest officers and retired Forest
                          officers of the State.
                        </i>
                      </li>
                      <br />
                      <li>
                        <i
                          >Officers of the other Departments, Officers of
                          Central Government, Officers of other State Government
                          and PSUs.
                        </i>
                      </li>
                      <br />
                      <li><i>General public.”</i></li>
                    </ul>
                    <br />
                  </li>
                  <li>
                    Reservation to rest house will be made on first come first
                    serve basis within each category.
                  </li>
                  <li>
                    No officer in service or retired shall be allowed to stay in
                    a Forest Inspection Bungalow for more than 5 days at a
                    stretch.
                  </li>
                  <li>
                    No private person (General public) shall be allowed to stay
                    in a Forest Inspection Bungalow for more than 2 days at a
                    stretch.
                  </li>
                  <li>
                    Reservations of rooms in all Forest Inspection Bungalows of
                    Kerala Forest Department will be only through the online
                    platform.
                  </li>
                  <li>
                    Depending on the availability and based on the priority in
                    Clause 2 above, reservation will be made and intimated to
                    the party/ official through the online Portal.
                  </li>
                  <li>
                    Once a room is reserved, it shall not be cancelled normally.
                    However the reservation made is liable for cancellation in
                    case of emergencies and public exigencies with notice as far
                    as possible.
                  </li>
                  <li>
                    Provisions of Clause 7 of APPENDIX II, (Paragraph 12.2.3.,
                    Volume 1) [Rules regulating the Occupation of Forest
                    Bungalows and Forest Rest Houses] of the Kerala Forest Code
                    Volume III shall apply regarding notice to any occupier to
                    vacate a building under occupation.
                    <i
                      >The Clause 7 reads as follows: <br />
                      <br />
                      The following shall be sufficient notice to any occupier
                      to vacate a building under occupation: <br />
                    </i>
                    <br />
                    <ul>
                      <li>
                        <i>
                          A Gazetted officer should, vacate in favour of another
                          Gazetted officer on receipt of 24 hours Notice.
                        </i>
                      </li>
                      <br />
                      <li>
                        <i>
                          A Non-Gazetted officer should vacate in favour of a
                          Gazetted Officer on receipt of six hours notice and in
                          favour of another Non-Gazetted Officer on receipt of
                          24 hours notice.
                        </i>
                      </li>
                      <br />
                      <li>
                        <i>
                          A Non Official should vacate in favour of an Official
                          on receipt of 6 hours notice and in favour of another
                          non-official on receipt of 24 hours notice.
                        </i>
                      </li>
                      <br />
                    </ul>
                  </li>
                  <li>The reservation made is not transferable.</li>
                  <li>Check out time is 24 hours.</li>
                  <li>
                    The rent has to be levied as per the rates fixed in the
                    Government Order from time to time and will be collected for
                    each occupant. The terms and conditions regarding the rent
                    of Forest Inspection Bungalows will be governed by the
                    provisions in Clause 6 of APPENDIX II, (Paragraph 12.2.3.,
                    Volume 1) [Rules regulating the Occupation of Forest
                    Bungalows and Forest Rest Houses] of the Kerala Forest Code
                    Volume III.
                  </li>
                  <li>
                    If a person having reservation on a particular day does not
                    turn up on that day, the reservation will be treated as
                    cancelled automatically.
                  </li>
                  <li>
                    A maximum of two adults and two children will be allowed to
                    stay in one room.
                  </li>
                  <li>
                    Occupants should carry valid ID proof (copy of which had
                    been uploaded in the online portal while making the
                    reservation) .
                  </li>
                  <li>
                    Reservation of a room in rest houses/ IBs does not entitle a
                    person to visit forest area.
                  </li>
                  <li>
                    Persons occupying Forest Inspection Bungalows are expected
                    to exercise due consideration and care for the building,
                    furniture and other articles therein and defacing of walls
                    and willful damage to or spoiling of furniture etc. will
                    render the occupants liable to expulsion from the buildings
                    besides payment of damages.
                  </li>
                  <li>
                    The watchers in charge of forest bungalows and rest houses
                    are not expected to cater for the occupiers.
                  </li>
                  <li>
                    Terms and Conditions not explicitly covered herein shall be
                    governed by APPENDIX II, specifically Paragraph 12.2.3 in
                    Volume 1 [Rules regulating the Occupation of Forest
                    Bungalows and Forest Rest Houses] of the Kerala Forest Code
                    Volume III and the guidelines issued for the purpose by the
                    Principal Chief Conservator of Forests & Head of Forest
                    Force, Kerala
                  </li>
                </ul>
              </span>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="roomincrementdialog" width="500">
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs10 pa-2>
            {{ msg }}
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex xs2 pr-2 pb-5>
            <v-btn block color="#3652AD" @click="addBooking()"
              ><span style="color: white">OK</span></v-btn
            >
          </v-flex>
          <v-flex xs2 pl-2 pb-5>
            <v-btn block color="#B80000" @click="roomincrementdialog = false"
              ><span style="color: white">Cancel</span></v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="submitdialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Please enter the details below and proceed for payment.</v-card-title
        >
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentname"
                      :rules="[(v) => !!v || 'Name is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Name
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentaddress"
                      :rules="[(v) => !!v || 'Address is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Address
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentphonenumber"
                      :rules="[
                        (v) => !!v || 'Phone number is required',
                        (v) =>
                          /^[0-9]{10}$/.test(v) ||
                          'Phone number must have 10 digits',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Phone Number
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentpincode"
                      :rules="[
                        (v) => !!v || 'Pincode is required',
                        (v) =>
                          /^[0-9]{6}$/.test(v) || 'Pincode must have 6 digits',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Pincode
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentpancardnumber"
                      :rules="[
                        (v) => !!v || 'PAN Card Number is required',
                        (v) =>
                          /^[A-Za-z0-9]{10}$/.test(v) ||
                          'PAN Card Number must have exactly 10 characters',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          PAN Card Number
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymenttaxid"
                      :rules="[(v) => !!v || 'TAX Id is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          TAX Id
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="paymentdialog()" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="green"
            class="mainfont"
            text
            :disabled="submitted"
            @click="addStudent1()"
          >
            {{ submitted ? "Processing..." : "Proceed For Payment" }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      confirmDialog: false,
      availableRooms: null,
      selectedDates: new Date(),
      availabilityformatfromdate: new Date(),
      availabilityformattodate: new Date(),
      date: new Date(),
      range: null,
      selectedDates2: [],
      type: "month",
      end: "2019-01-06",
      appLoading: false,
      showSnackBar: false,
      idtype: null,
      idfile: null,
      idNo: null,
      submitted: false,
      getdetailsdata: "",
      msg: null,
      paymentphonenumber: "",
      paymentpancardnumber: "",
      paymentname: "",
      paymenttaxid: "",
      paymentpincode: "",
      paymentaddress: "",
      amountperday: 0,
      minDate: new Date().toISOString().substr(0, 10),
      disabledDays: [new Date(2023, 9, 9)],
      keyArray: [],
      availabledate: {},
      selectedSeats: 20,
      itemArray2: ["Vegeterian", "Non-vegetarian"],
      userTypeArray: ["Public", "Official"],
      priorityArray: [
        "MINISTER",
        "SPEAKER",
        "DEPUTY SPEAKER",
        "GOVERNMENT CHIEF WHIP",
        "MP",
        "MLA",
        "HIGH COURT OF KERALA JUDGE",
        "ADVOCATE GENERAL",
        "CHIEF SECRETARY",
        "ADDITIONAL CHIEF SECRETARY/PRINCIPAL SECRETARY/SECRETARY (FORESTS AND WILDLIFE DEPARTMENT)",
        "FOREST OFFICERS ON INSPECTION",
        "FOREST OFFICER",
        "RETIRED FOREST OFFICER",
        "OFFICER OF OTHER DEPARTMENT",
        "OFFICER OF CENTRAL GOVERNMENT",
        "OFFICER OF OTHER STATE GOVERNMENT",
        "OFFICER OF PSU",
        "GENERAL PUBLIC",
      ],
      prioritystatusArray: ["Official", "Private"],
      usertype: null,
      accid: null,
      guestCount: false,
      guestName: null,
      guestEmailId: null,
      guestMobileNo: null,
      guestAddress: null,
      // formData:new formData(),
      idcardtypes: ["Election Id Card", "Driving License", "Aadhaar Card"],
      studentDetails: [],
      selectedDate: [],
      referredBy: "",
      referencePersonPh: null,
      memberdialog: false,
      mealsType: null,
      todaytDateFormatted: null,
      personsAccompanyingRules: null,
      noOfVehicles: null,
      vehicleNumbers: null,
      anyOtherDetails: null,
      reqRooms: null,
      accomodationName: null,
      children: 0,
      adult: 0,
      radio: null,
      nowDate: "",
      tandc: false,
      selectedFile: [],
      pickerDate: null,
      finalarr: [],
      submitdialog: false,
      ibdata: "",
      disableddates: [],
      tick: false,
      priority: null,
      priorityFile: null,
      roomincrementdialog: false,
      myStoredVariable: null,
      prioritystatus: null,
      isCheck: false,

      // nowDate: new Date().toISOString().slice(0, 10),
      // minBookingDate: new Date(), // Set this to the current date or the start of the allowed booking period
      maxBookingDate: new Date(new Date().setMonth(new Date().getMonth() + 2)),
    };
  },
  beforeMount() {
    this.myStoredVariable = localStorage.getItem("utype");

    if (this.myStoredVariable) {
      console.log(
        "Retrieved variable from localStorage:",
        this.myStoredVariable
      );
    } else {
      console.error("Variable not found in localStorage or is null/undefined");
    }
  },
  mounted() {
    this.todaytDate = new Date();
    this.getList();
    // Format the date as dd-mm-yyyy
    const day = this.todaytDate.getDate().toString().padStart(2, "0");
    const month = (this.todaytDate.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
    const year = this.todaytDate.getFullYear();

    this.todaytDateFormatted = `${day}-${month}-${year}`;

    console.log("todaytDateFormatted==", this.todaytDateFormatted);
    if (this.todaytDateFormatted) {
      this.checkAvailability(
        this.availabilityformatfromdate,
        this.availabilityformattodate
      );
    }
    this.getData();
  },
  computed: {
    totalCount() {
      return Number(this.children) + Number(this.adult);
    },
    totalAmount() {
      return this.reqRooms * this.amountperday;
    },
    nowDatee() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    },

    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
          "E-mail must be valid",
      ];
    },
    addressRules() {
      return [
        (v) => !!v || "Address is required",
        (v) => /^[a-zA-Z0-9., ]+$/.test(v) || "Address must be valid",
      ];
    },
    guestNameRules() {
      return [
        (v) => !!v || "Name is required",
        (v) =>
          /^[A-Za-z\s]+$/.test(v) ||
          "Name must contain only alphabets and spaces",
        (v) => v.length <= 30 || "Name must not exceed 20 characters",
      ];
    },
    roomsRequiredRules() {
      return [(v) => !!v || "Number of rooms is required"];
    },
    // personsAccompanyingRules() {
    //     return [
    //         () => {
    //         const sum = this.children + this.adult;
    //         return sum >= 1 || "Number of persons should be 1 or more";
    //         },
    //     ];
    // },
    idTypeRules() {
      return [(v) => !!v || "ID type is required"];
    },
    electionIdRules() {
      return [
        (v) => !!v || "Election ID is required",
        (v) =>
          /^[A-Z]{3}[0-9]{7}$/.test(v) ||
          "Election ID number must be 10 digits",
      ];
    },
    anyOtherDetailsRules() {
      return [
        (v) =>
          /^[A-Za-z0-9 ]+$/.test(v) || "Special Charecters are not allowed",
      ];
    },
    aadharCradRules() {
      return [
        (v) => !!v || "Aadhar Number is required",
        (v) => /^[0-9]{12}$/.test(v) || "Aadhar number must be 12 digits",
      ];
    },
    drivingLicenseRules() {
      return [
        (v) => !!v || "License Number is required",
        (v) =>
          /^[A-Z]{2}[0-9]{13}$/.test(v) ||
          "License number must be 15 charecters",
      ];
    },
    priorityRules() {
      return [(v) => !!v || "Designation is required"];
    },
    statusRules() {
      return [(v) => !!v || "Status is required"];
    },
    usertypeRules() {
      return [(v) => !!v || "user Type is required"];
    },

    computedMaxDueDate() {
      console.log("cscsdsdcdsds");
      const today = moment();
      const lastDayOfMonth = moment(today).endOf("month");
      return lastDayOfMonth.format("YYYY-MM-DD");
    },
    formattedSelectedMonth() {
      return moment(this.selectedDates).format("MMMM YYYY");
    },
    // formattedSelectedDate() {
    //   return moment(this.selectedDates2).format("MMMM D, YYYY");
    // },
    // formattedSelectedDates() {
    //   return this.selectedDates
    //     .map((date) => moment(date).format("MMMM D, YYYY"))
    //     .join(", ");
    // },
  },
  watch: {
    personsAccompanyingRules() {
      return [
        () => {
          const sum = Number.this.children + Number.this.adult;
          return sum >= 1 || "Number of persons should be 1 or more";
        },
      ];
    },

    selectedDate() {
      console.log("first selected dated=", this.selectedDate);
      if (this.selectedDate.length > 1) {
        this.newfun();
      } else {
        this.finalarr = this.selectedDate.map((date) => {
          const [year, month, day] = date.split("-");
          return `${year}-${month}-${day}`;
        });

        console.log("finalarr single=", this.finalarr);
        this.checkAvailability(
          this.finalarr[0],
          this.finalarr[this.finalarr.length - 1]
        );
      }
    },
    reqRooms() {
      if (this.reqRooms > this.availableRooms) {
        // this.msg = `Only ${this.availableRooms} Rooms Available`;
        this.memberdialog = true;

        // this.showSnackBar = true;
      } else {
        // this.showSnackBar = false;
      }
    },
  },
  methods: {
    // countadult() {
    //   if (this.adult > this.reqRooms * 2) {
    //     this.reqRooms = this.reqRooms+1;
    //   } else {
    //     this.reqRooms = 0;
    //   }
    // },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/accomodation",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          accid: this.$route.query.id,
        },
      })
        .then((response) => {
          this.ibdata = response.data.data;
          this.amountperday = this.ibdata.perdayrate;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    truncateFileName(name, maxLength = 15) {
      if (name.length > maxLength) {
        return name.substring(0, maxLength) + "...";
      }
      return name;
    },
    changeCursor() {
      document.getElementById("readMore").style.cursor = "pointer";
    },
    restoreCursor() {
      document.getElementById("readMore").style.cursor = "auto";
    },

    displayDate(dateString) {
      if (!dateString) {
        return ""; // Return an empty string if dateString is falsy
      }

      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return ""; // Return an empty string if dateString is not a valid date
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

    calculateMaxDate() {
      const twoMonthsFromNow = new Date();
      twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);
      return twoMonthsFromNow.toISOString().split("T")[0];
    },

    terms() {
      this.tandc = true;
    },

    submit2() {
      this.reqRooms = null;
      this.memberdialog = false;
    },

    handleFileChange(event) {
      var itemFiles = event.target.files || event.dataTransfer.files;
      if (this.selectedFile) {
        for (let i = 0; i < itemFiles.length; i++) {
          this.selectedFile.push(itemFiles[i]);
        }
      }
      console.log("files", this.selectedFile);
      // this.selectedFile = event.target.files[0];
    },
    removeFiles(i) {
      this.selectedFile.splice(i, 1);
    },
    paymentdialog() {
      this.submitdialog = false;
      this.submitted = false;
      this.paymentphonenumber = "";
      this.paymentname = "";
      this.paymentpancardnumber = "";
      this.paymentaddress = "";
      this.paymentpincode = "";
      this.paymenttaxid = "";
    },
    handleFileChangePriority(event) {
      this.priorityFile = event.target.files[0];
      this.uploadFile();
    },
    uploadFile() {
      if (this.priorityFile) {
        const formData = new FormData();
        formData.append("file", this.priorityFile);
        console.log("File selected", this.priorityFile);
      } else {
        console.error("No file selected");
      }
    },
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    add() {},
    validation() {
      if (!this.reqRooms) {
        alert("Please entre No. of Rooms Required");
        return;
      } else if (!this.radio) {
        alert("Please select Official or Public");
        return;
      } else if (!this.guestName) {
        alert("Please entre Guest Name");
        return;
      } else if (!this.guestMobileNo) {
        alert("Please enter the Phone Number");
        return;
      } else if (!this.priority && this.radio === "Official") {
        alert("Please select the Category");
        return;
      } else if (
        !this.prioritystatus &&
        (this.priority === "MINISTER" ||
          this.priority === "SPEAKER" ||
          this.priority === "DEPUTY SPEAKER" ||
          this.priority === "GOVERNMENT CHIEF WHIP" ||
          this.priority === "MP" ||
          this.priority === "MLA" ||
          this.priority === "HIGH COURT OF KERALA JUDGE" ||
          this.priority === "ADVOCATE GENERAL" ||
          this.priority === "CHIEF SECRETARY" ||
          this.priority ===
            "ADDITIONAL CHIEF SECRETARY/PRINCIPAL SECRETARY/SECRETARY (FORESTS AND WILDLIFE DEPARTMENT)" ||
          this.priority === "FOREST OFFICERS ON INSPECTION" ||
          this.priority === "MINISTER" ||
          this.priority === "SPEAKER" ||
          this.priority === "DEPUTY SPEAKER" ||
          this.priority === "GOVERNMENT CHIEF WHIP" ||
          this.priority === "MP" ||
          this.priority === "MLA" ||
          this.priority === "HIGH COURT OF KERALA JUDGE" ||
          this.priority === "ADVOCATE GENERAL" ||
          this.priority === "CHIEF SECRETARY" ||
          this.priority ===
            "ADDITIONAL CHIEF SECRETARY/PRINCIPAL SECRETARY/SECRETARY (FORESTS AND WILDLIFE DEPARTMENT)" ||
          this.priority === "FOREST OFFICER" ||
          this.priority === "RETIRED FOREST OFFICER")
      ) {
        // if(!this.prioritystatus){
        alert("Please select the priority Status");
        return;
        // }
      } else if (!this.guestEmailId) {
        alert("Please enter Email");
        return;
      } else if (!this.idtype) {
        alert("Please select the ID type");
        return;
      } else if (!this.idNo) {
        alert("Please enter ID Number");
        return;
      } else if (!this.selectedFile) {
        alert("Please upload the PDF file to upload");
        return;
      } else if (this.adult != this.selectedFile.length) {
        alert("No:PDF file sholud be same as No:Guests");
        console.log("count", this.adult, this.selectedFile.length);
        return;
      } else if (this.usertype === "Official" && !this.priorityFile) {
        alert("Please upload the PDF file to upload");
        return;
      } else if (this.finalarr.length <= 0) {
        alert("Please select the Dates");
        return;
      } else if (this.isCheck === false) {
        alert("Accept the Terms and Conditions");
        return;
      } else this.submitdialog = true;
    },

    addStudent1() {
      if (!this.paymentname) {
        this.msg = "Provide Name For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentaddress) {
        this.msg = "Provide Address For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentphonenumber) {
        this.msg = "Provide Phone Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpincode) {
        this.msg = "Provide Pincode For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpancardnumber) {
        this.msg = "Provide PAN Card Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymenttaxid) {
        this.msg = "Provide Tax Id For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{10}$/.test(this.paymentphonenumber)) {
        this.msg = "Phone Number must be 10 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{6}$/.test(this.paymentpincode)) {
        this.msg = "Pincode must be 6 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[A-Za-z0-9]{10}$/.test(this.paymentpancardnumber)) {
        this.msg =
          "PAN Card Number must have exactly 10 characters.";
        this.showSnackBar = true;
        return;
      }

      this.submitted = true;
      const studentNew = {};
      this.studentDetails.push(studentNew);
      console.log("selectedFile=", this.selectedFile);
      const formData = new FormData();
      if (this.selectedFile.length > 0) {
        // const formData = new FormData();

        // Append each file individually
        for (let i = 0; i < this.selectedFile.length; i++) {
          formData.append("idfile", this.selectedFile[i]);
        }
      }

      formData.append("fromDate", this.finalarr[0]);
      formData.append("toDate", this.finalarr[this.finalarr.length - 1]);
      formData.append("accid", this.$route.query.id);
      formData.append("guestCount", this.guestCount);
      formData.append("guestName", this.guestName);
      // formData.append("idfile", JSON.stringify(this.selectedFile));
      formData.append("idNo", this.idNo);
      formData.append("idtype", this.idtype);
      formData.append("guestEmailId", this.guestEmailId);
      formData.append("guestMobileNo", this.guestMobileNo);
      formData.append("guestAddress", this.guestAddress);
      formData.append("referredBy", this.referredBy);
      formData.append("referencePersonPh", this.referencePersonPh);
      formData.append("mealsType", this.mealsType);
      formData.append("vehicleNumbers", JSON.stringify(this.vehicleNumbers));
      formData.append("noOfVehicles", this.noOfVehicles);
      formData.append("anyOtherDetails", this.anyOtherDetails);
      formData.append("reqRooms", this.reqRooms);
      formData.append("noofchildren", JSON.stringify(this.children));
      formData.append("noofadults", JSON.stringify(this.adult));
      // formData.append("priority", this.priority);
      // formData.append("prioritystatus", this.prioritystatus);
      // formData.append("priorityDoc", this.priorityFile);

      if (this.radio === "Official") {
        formData.append("priority", this.priority);
        formData.append("prioritystatus", this.prioritystatus);
        formData.append("priorityDoc", this.priorityFile);
      } else if (this.radio === "Public") {
        formData.append("priority", "GENERAL PUBLIC");
      }
      formData.append("proceed", "No");
      formData.append("amount", this.totalAmount);
      formData.append("address", this.paymentaddress);
      formData.append("mobnumber", this.paymentphonenumber);
      formData.append("pin_no", this.paymentpincode);
      formData.append("pan_no", this.paymentpancardnumber);
      formData.append("tax_id", this.paymenttaxid);
      formData.append("party_name", this.paymentname);
      axios({
        method: "POST",
        url: "/add/booking",
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getdetailsdata = response.data.data;
            this.openFormInNewWindow();
            // console.log("getdetailsdata",this.getdetailsdata);
            this.$router.push({ path: "/IB" });
            if (response.data.variable === "Yes") {
              // console.log(response.data.variable);
              this.roomincrementdialog = true;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.$router.push({ path: "/UserMybookings" });
              this.submitted = false;
            }

            // setTimeout(() => {
            //   location.reload();
            // }, 4000);
            // location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    openFormInNewWindow() {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
        "https://etreasury.kerala.gov.in/api/eTreasury/service/ChallanGeneration.php"; // Replace with your actual endpoint URL
      form.target = "_blank"; // Open in a new window or tab

      // Create hidden input fields for 'input_data' and 'input_headers'
      const inputDataField = document.createElement("input");
      inputDataField.type = "hidden";
      inputDataField.name = "input_data";
      inputDataField.value = JSON.stringify({
        HMAC: this.getdetailsdata.HMAC, // Replace with actual HMAC value
        DATA: this.getdetailsdata.DATA,

        // Replace with actual encrypted data
      });

      const inputHeadersField = document.createElement("input");
      inputHeadersField.type = "hidden";
      inputHeadersField.name = "input_headers";
      inputHeadersField.value = JSON.stringify({
        clientId: this.getdetailsdata.clientId,
        authToken: this.getdetailsdata.authToken,
        // Replace with actual auth token
      });

      // Append hidden inputs to the form
      form.appendChild(inputDataField);
      console.log("inputDataField", inputDataField);
      form.appendChild(inputHeadersField);
      console.log("inputHeadersField", inputHeadersField);

      // Append form to the body (required to submit it)
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the DOM after submission
      document.body.removeChild(form);
    },
    addBooking() {
      // if (!this.selectedFile) {
      //   alert("Please select a PDF file to upload");
      //   return;
      // }
      const studentNew = {};
      this.studentDetails.push(studentNew);
      console.log("selectedFile=", this.selectedFile);
      const formData = new FormData();
      if (this.selectedFile.length > 0) {
        // const formData = new FormData();

        // Append each file individually
        for (let i = 0; i < this.selectedFile.length; i++) {
          formData.append("idfile", this.selectedFile[i]);
        }
      }

      formData.append("fromDate", this.finalarr[0]);
      formData.append("toDate", this.finalarr[this.finalarr.length - 1]);
      formData.append("accid", this.$route.query.id);
      formData.append("guestCount", this.guestCount);
      formData.append("guestName", this.guestName);
      // formData.append("idfile", JSON.stringify(this.selectedFile));
      formData.append("idNo", this.idNo);
      formData.append("idtype", this.idtype);
      formData.append("guestEmailId", this.guestEmailId);
      formData.append("guestMobileNo", this.guestMobileNo);
      formData.append("guestAddress", this.guestAddress);
      formData.append("referredBy", this.referredBy);
      formData.append("referencePersonPh", this.referencePersonPh);
      formData.append("mealsType", this.mealsType);
      formData.append("vehicleNumbers", JSON.stringify(this.vehicleNumbers));
      formData.append("noOfVehicles", this.noOfVehicles);
      formData.append("anyOtherDetails", this.anyOtherDetails);
      formData.append("reqRooms", this.reqRooms);
      formData.append("noofchildren", JSON.stringify(this.children));
      formData.append("noofadults", JSON.stringify(this.adult));
      // formData.append("priority", this.priority);
      // formData.append("prioritystatus", this.prioritystatus);
      // formData.append("priorityDoc", this.priorityFile);

      if (this.radio === "Official") {
        formData.append("priority", this.priority);
        formData.append("prioritystatus", this.prioritystatus);
        formData.append("priorityDoc", this.priorityFile);
      } else if (this.radio === "Public") {
        formData.append("priority", "GENERAL PUBLIC");
      }
      formData.append("proceed", "No");

      axios({
        method: "POST",
        url: "/add/booking",
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            console.log(response.data.variable);
            this.showSnackBar = true;
            this.$router.push({ path: "/UserMybookings" });
            // setTimeout(() => {
            //   location.reload();
            // }, 4000);
            // location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    availabilityFormatDate(date) {
      // Split the date into day, month, and year
      const parts = date.split(" ");

      // Rearrange the parts to format as YYYY-MM-DD
      const availabilityformatDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

      return availabilityformatDate;
    },

    checkAvailability(fromdate, todate) {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          fromDate: fromdate,
          toDate: todate,
          accid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.availableRooms = response.data.availableRooms;
            this.range = response.data.accomodationDetails.rangeid.officeName;
            this.accomodationName = response.data.accomodationName;
            // this.msg = response.data.msg;
            // this.showSnackBar = true;
          } else {
            this.memberdialog = true;

            this.msg = response.data.msg;
            // this.tandc = true;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    getData() {
      axios({
        method: "POST",
        url: "/listblockdates/acc",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          accid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.disableddates = response.data.data;
          } else {
            this.msg = response.data.msg;
            // this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    newfun() {
      function formatDate(date) {
        const dd = String(date.getDate()).padStart(2, "0");
        const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
        const yy = String(date.getFullYear());

        return `${yy}-${mm}-${dd}`;
      }

      function getDates(startDate, endDate) {
        const dateArray = [];
        const currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
          dateArray.push(formatDate(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
      }
      var result = [];
      if (new Date(this.selectedDate[0]) <= new Date(this.selectedDate[1])) {
        console.log("1");
        result = getDates(this.selectedDate[0], this.selectedDate[1]);
      } else {
        console.log("2");

        result = getDates(this.selectedDate[1], this.selectedDate[0]);
        console.log("before-rearragedate==", this.selectedDate);
        var newarr3 = [this.selectedDate[1], this.selectedDate[0]];
        this.selectedDate = newarr3;
        console.log("rearragedate==", newarr3);
      }
      //   const result = getDates(this.selectedDate[0], this.selectedDate[1]);
      this.checkDisabledDates(result);
      this.checkAvailability(result[0], result[result.length - 1]);
    },

    checkDisabledDates(result) {
      function formatDate(date) {
        const dd = String(date.getDate()).padStart(2, "0");
        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const yy = String(date.getFullYear());

        return `${dd}-${mm}-${yy}`;
      }

      // Convert date strings to Date objects
      const dateObjects = this.disableddates.map(
        (dateString) => new Date(dateString)
      );

      // Use map to convert each element in dateObjects
      const newdisableddates = dateObjects.map((date) => formatDate(date));

      const overlappingDates = newdisableddates.filter((date) =>
        result.includes(date)
      );
      console.log("overlappingDates=", overlappingDates);

      // console.log("disableddates=", this.disableddates);
      console.log("newdisableddates=", newdisableddates);

      if (overlappingDates.length > 0) {
        this.showSnackBar = true;
        this.msg = "You cannot choose these dates";
      } else {
        console.log("result is=", result);
        this.showSnackBar = false;
        this.msg = "";
        this.finalarr = result;
        console.log("finalarr multiple=", this.finalarr);
      }
    },
    filterAllowedDates(val) {
      const date = new Date(val);
      // Check if the date is not in the disabledDates array
      return !this.disableddates.includes(this.formatDate2(date));
    },
    formatDate2(date) {
      // Format the date as 'YYYY-MM-DD' for comparison
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style>
.selectedDates {
  background-color: green;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.rounded {
  border-radius: 25px;
}

.selectedDates {
  background-color: green;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
